import { IconButton } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import React from 'react'
import styled from 'styled-components'
const FaqWrapperStyle = styled.div`
	position: fixed;
	z-index: 999;
	border-radius: 50%;
	bottom: 8px;
	right: 8px;
`

const Faq = () => {
	return (
		<FaqWrapperStyle>
			<IconButton
				onClick={() => {
					// @ts-ignore
					jivo_api.open()
					return false
				}}
				color='default'
				style={{ width: 64, height: 64 }}
				size='medium'
			>
				<ChatIcon name='chat' color='primary' style={{ fontSize: '32px' }} />
			</IconButton>
		</FaqWrapperStyle>
	)
}

export default Faq
